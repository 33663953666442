export const environment = {
  version: '1.1.17D',
  production: false,
  captchaSVK: '6Le-etEUAAAAAG11VOp7m9XydFcEsBaVe2ePw2pR',
  aternaServiceEmisionConciliacion: 'https://aterna-ingress-dev.gc.gentera.com.mx/api/emisionback/v1/',
  key_map:'AIzaSyCpVhQiwAllg1RAFaxMWSpQruuGARy0Y1k',
  services: {
    catCausaSiniestro: 'https://ent-platf-emision-back-go-o5x3qqa7gq-ue.a.run.app/api/v1/emisionback/'
  },
  pathService:{
    login:'login',
    refresh_token: 'refresh_token'
  }
};
